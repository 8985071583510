import React from "react"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import { MDXProvider } from "@mdx-js/react"

const FaqCard = (props) => {
	const expandCard = (event) => {
		event.currentTarget.classList.toggle("expanded");
		event.currentTarget.firstElementChild.classList.toggle("rotate");
		let content = event.currentTarget.nextElementSibling;
		if (content.style.display === "block") {
			content.style.display = "none";
		  } else {
			content.style.display = "block";
		  }
		}
	return (
			<div className="faq-card" data-sal="slide-up" data-sal-easing="ease">
				<div className="question" onClick={expandCard}>
					<p>→</p>
					<h3>{props.content.question}</h3>
				</div>
				<div className="answer" id={props.content.counter.toString()}>
					<MDXProvider>
						<MDXRenderer>{props.content.answer}</MDXRenderer>
					</MDXProvider>
				</div>
			</div>
		)
}

export default FaqCard

